$white:                     #ffffff;
$black:                     #000000;
$curious-blue:              #20A5CE;
$picton-blue:               #46CCE1;
$mercury:                   #E8E8E8;
$elephant:                  #0F3249;
$dove-gray:                 #666666;
$alabaster:                 #F7F7F7;
$cerulean:                  #00A7CF;
$gallery:                   #EAEAEA;
