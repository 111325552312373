.hr {
  width: 70px;
  height: 3px;
  border-radius: 3rem;
}

$sizes: 1,2,3,4,5,6,7,8,9,10;
@each $i in $sizes {
	.size-#{$i} {
		height: #{$i}px;
	}
}
