@import '../../assets/scss/bootstrap/functions';
@import '../../assets/scss/custom/variables';

.fluidContainer {
  z-index: 0;
  position: absolute;
}

.formContainer {
  z-index: 1;
  position: relative;
}

.fluidData {
  z-index: 2;
}

.icon {
  width: 5rem;
  height: 5rem;
}

.gradientSection {
  border-radius: 0.375rem 0 0 0.375rem;
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {
  .fluidContainer {
    position: relative;
    top: 1rem !important;
  }

  .gradientSection {
    border-radius: 0.375rem 0.375rem 0 0;
  }
}
