.sort {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  margin-left: 10px;
}

.tableStyle {
  table-layout: fixed;
  word-wrap: break-word;

  .tableHeader {
    cursor: pointer;
    width: fit-content;
  }
}
