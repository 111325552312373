.confirmEmailWrap {
  padding: 4em 0;
  text-align: center;

  h3 {
    font-size: 1.5em;
    line-height: 2em;
  }

  .title {
    display: block;
    padding: 1.5em 0;
    font-weight: bold;
  }
}