@import '../../../../assets/scss/bootstrap/functions';
@import '../../../../assets/scss/custom/variables';

.bannerItem {
  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    width: 100%;
    margin-bottom: 45px;
  }
}

.caption {
  background-color: $white;
  left: auto;
  right: 15px;
  bottom: 10px;
  min-width: 35%;
  max-width: 47%;
  position: absolute;
  height: 90px;

  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
}

.controls {
  position: absolute;
  bottom: 10%;
  height: 50px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  margin-left: 6rem;

  &[lang="he"] {
    right: 61%;
    margin-left: unset;
  }

  ol {
    bottom: 8px !important;
    padding: 0 10%;
  }

  a {
    z-index: 4;
  }
}

.numberIndicator {
  padding: 3rem;
  margin: 0 !important;
  z-index: 0;
  &:last-child {
    opacity: 0.6;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .bannerItem {
    min-height: 324px;
  }
  .bannerTitle {
    font-size: 2.1875rem !important;
  }
  .controls {
    bottom: 0%;

    &[lang="he"] {
      right: 1.5rem;
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {
  .controls {
    left: 50%;
    margin-left: -150px;
    bottom: -4%;

    &[lang="he"] {
      right: unset;
      margin-left: -150px;
    }
  }
  .caption a i, .caption a span {
    font-size: 1rem;
  }
  .caption {
    min-width: 75%;
    height: 70px;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
  .controls {
    bottom: -7%;
  }
}
