@import "./colors";
@import './fontMixins';

:global {
  .blue-button {
    height: 67px;
    width: 100%;
    margin-top: 38px;

      background: $curious-blue;
      color: $white;
      border-radius: 3.79px;
      border: none;
      font-size: 26.86px;
      &:hover, &:focus {
        background: $curious-blue;
        border-color: $curious-blue;
        color: $white;
      }
  }

  .auth-controls {
    @include helvetica-neue;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & > button {
      @extend .blue-button;

      @media only screen and (max-width: 1280px) {
        font-size: 12px;
        height: 30px;
        margin-top: 15px;
      }
    }
  }
}

.form-input {
  align-self: stretch;
  margin-bottom: 0;
  line-height: 1;

  input {
    @include helvetica-neue;
    border-color: $mercury;
    height: 58px;
    padding-left: 22px;

    @media only screen and (max-width: 1280px) {
      height: 30px;
      padding-left: 10px;
    }
  }

  :global {
    .ant-form-item-control-wrapper:first-of-type > .ant-form-item-control > .ant-form-item-children > .ant-row.ant-form-item {
      margin-bottom: 0;
      margin-top: 10px;

      @media only screen and (max-width: 1280px) {
        margin-top: 0;
      }
    }

    .ant-form-item-label {
      @include helvetica-neue-medium;
      font-size: 16px;
      line-height: 19.2px;
      letter-spacing: 1px;
      text-align: left;
      color: $black;

      label {
        white-space: normal;
        text-transform: uppercase;
        font-size: 16px;
        &:after {
          display: none;
        }

        @media only screen and (max-width: 1280px) {
          font-size: 12px;
          line-height: 13px;
          letter-spacing: 0;
        }
      }
    }

    .ant-form-item-control {
      line-height: 1;

      .ant-input {
        padding-right: 0;
        margin-top: 10px;

        @media only screen and (max-width: 1280px) {
          font-size: 11px;
        }
      }
      &.has-success .ant-form-item-children:after {
        display: none;
      }
    }
  }
}
