@import "../../assets/styles/colors";
@import "../../assets/styles/fontMixins";

.root {
  //
}
.header {
  background: $elephant;
}
.menu {
  line-height: 64px;
  background: $elephant;
}

.logout {
  float: right;
}

.content {
  &.home {
    background-color: inherit;
  }
}

.footer {
  text-align: right;
  background: $white;

  ul {
    @include myriad-pro-regular;
    margin: 0;
    padding: 0;

    & > li {
      display: inline-block;
      margin: 0 20px;

      a {
        color: $picton-blue;

        &:focus {
          text-decoration: none;
        }
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
