//
// Custom checkbox
//

.custom-checkbox {
    .custom-control-input ~ .custom-control-label {
        cursor: pointer;
        font-size: $font-size-sm;
    }

    .custom-control-input {
        &:checked {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-checked-border-color;
                }
                &::after {
                    background-image: $custom-checkbox-indicator-icon-checked;
                }
            }
        }

        &:disabled {
            ~ .custom-control-label {
                &::before {
                    border-color: $custom-control-indicator-disabled-bg;
                }
            }

            &:checked {
                &::before {
                    border-color: $custom-control-indicator-checked-disabled-bg;
                }
            }
        }
    }
}

.form-actions-row {
    @include media-breakpoint-up(md) {
        position: sticky;
        top: 0;
        z-index: 10;
        background-color: $body-bg;
    }
}