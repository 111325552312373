@font-face {
  font-family: 'CodeProLC';
  src: url('../fonts/CodeProLC.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'GothaProMed';
  src: url('../fonts/GothaProMed.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'MyriadProRegular';
  src: url('../fonts/MyriadPro-Regular.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/Helvetica-01.ttf?pokwqw') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Bold';
  src: url('../fonts/Helvetica-Bold-02.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../fonts/HelveticaNeue-01.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Bold';
  src: url('../fonts/HelveticaNeue-Bold-02.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Light';
  src: url('../fonts/HelveticaNeue-Light-08.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeue Medium';
  src: url('../fonts/HelveticaNeue-Medium-11.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

