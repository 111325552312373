@import '../../assets/scss/bootstrap/functions';
@import '../../assets/scss/custom/variables';

.bannerItem {
  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
    width: 100%;
    margin-bottom: 45px;
  }
}

.caption {
  background-color: $white;
  left: auto;
  right: 15px;
  bottom: 10px;
  min-width: 35%;
  max-width: 47%;
  position: absolute;
  height: 90px;
  z-index: 10;

  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
}

.controls {
  position: absolute;
  bottom: 10%;
  height: 50px;
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  ol {
    bottom: 8px !important;
    padding: 0 10%;
  }

  a {
    z-index: 4;
  }
}

.numberIndicator {
  padding: 3rem;
  margin: 0 !important;
  z-index: 0;

  &:last-child {
    opacity: 0.6;
  }
}

.bannerOrderPanel {
  display: none;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 20;
  &.panelIsOpened {
    display: block;
  }

  .bannerOrderPanelContent {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: $white;
    max-height: 0;
    height: auto;
    overflow: hidden;
    -webkit-transition: max-height .3s ease-out;
    -moz-transition: max-height .3s ease-out;
    -ms-transition: max-height .3s ease-out;
    -o-transition: max-height .3s ease-out;
    transition: max-height .3s ease-out;

    &.panelIsOpened {
      max-height: 800px;
      -webkit-transition: max-height .5s linear;
      -moz-transition: max-height .5s linear;
      -ms-transition: max-height .5s linear;
      -o-transition: max-height .5s linear;
      transition: max-height .5s linear;
    }
  }

  .bannerCard {
    display: flex;
    padding: 0;
    margin: 10px;
    width: 250px;
    height: 170px;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $info;
    -webkit-transition: background .3s linear;
    -moz-transition: background .3s linear;
    -ms-transition: background .3s linear;
    -o-transition: background .3s linear;
    transition: background .3s linear;
    cursor: grab;

    p {
      font-size: 0.7rem;
      line-height: 1rem;
    }

    button {
      font-size: 0.3rem;
    }

    span {
      display: block;
      font-size: 0.1rem;
      line-height: 0.5rem;
    }

    &.dragging {
      border: 2px dashed $info;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: grabbing;
    }

    .dropHere {
      text-align: center;
      line-height: 170px;
      width: 100%;
    }

    .image {
      height: 130px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {
  .caption a i, .caption a span {
    font-size: 1rem;
  }
  .caption {
    min-width: 75%;
    height: 70px;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
  .controls {
    left: 50%;
    margin-left: -150px;
  }
}
