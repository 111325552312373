@import '../../assets/scss/bootstrap/functions';
@import '../../assets/scss/custom/variables';

.subscribe {
  background-color: $white;
  height: 50px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
