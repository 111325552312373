@import '../../../../assets/scss/bootstrap/functions';
@import '../../../../assets/scss/custom/variables';

.item {
  min-height: 520px;
}

.subscribe {
  background-color: $white;
  height: 50px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.indicator {
  padding: 1rem;
  cursor: pointer;
  display: block;
}

.previewText {
  max-height: 340px;
  overflow: hidden;
}

@media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
  .item {
    background-color: $white;
    box-shadow: $box-shadow;
  }
  .indicator {
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "sm")) {
  .indicator {
    padding: 0.5rem;
    display: flex;
    align-items: center;

    :global {
      .h4 {
        font-size: 0.8rem;
      }
    }
  }

  .item {
    min-height: 450px;
  }

  .previewText {
    max-height: 300px;
    overflow: hidden;
  }
}
