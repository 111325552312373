@import './fonts';
@import './forms';
@import './icons';
@import './colors';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}


body {
  :global {
    div.container {
      min-height: 100%;
      overflow: hidden;
    }
  }
}

body {
  counter-reset: section;
}

.ftn {
  &:before {
    counter-increment: section;
    content: counter(section) ". ";
  } 
  font-size: 0.8rem !important; 
}

.highlight {
  animation-name: backgroundColorPalette;
  animation-duration: 3s;
	animation-iteration-count: 1;
	animation-direction: alternate;
	animation-timing-function: linear; 
}

@keyframes backgroundColorPalette {
	0% {
		background: hsl(189, 93%, 50%);
	}
	100% {
		background: transparent;
	}
}

.table-striped>tbody>tr:nth-of-type(odd) {
     background-color: $mercury;
 }
