

.react-autosuggest__container {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.react-autosuggest__container--open {

}

.react-autosuggest__input {
  display: block;
  width: 100%;
  height: calc(2.75rem + 2px);
  padding: 0.625rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  -webkit-transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__input--focused {

}

.react-autosuggest__suggestions-container {
  position: absolute;
  top: 100%;
  width: 100%;
  background-color: #fff;
  border-radius: .4375rem;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: 11111;
}

.react-autosuggest__suggestions-container--open {
  border: 1px solid #dee2e6;
}

.react-autosuggest__suggestions-list {
  max-height: 570px;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: .5rem 0;
}

.react-autosuggest__suggestion {
  background-color: initial;
  color: #16181b;
  cursor: pointer;
  padding: .5rem .75rem;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-select: none;
}

.react-autosuggest__suggestion--first {

}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-autosuggest__section-container {

}

.react-autosuggest__section-container--first {

}

.react-autosuggest__section-title {

}