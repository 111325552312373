.title {
	font-size: 1.35rem;
	line-height: 1.3;
}

.subtitle {
	line-height: 1.3;
}

.imgStyle {
	width: 100%;
	box-shadow: 0px 1px 15px 5px rgba(0,0,0,0.2);
}
