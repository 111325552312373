@import "../../assets/styles/fontMixins";
@import "../../assets/styles/colors";

.container {
  width: 100%;
  padding: 30px 70px;
  background: $alabaster;

  @media only screen and (max-width: 1280px) {
    padding: 20px 30px;
  }
}

.title {
  @include code-pro;
  padding-left: 30px;
  font-size: 36px;
}

.card {
  margin-bottom: 15px;
  border-radius: 30px;
  min-height: 220px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 15px, rgba(0, 0, 0, 0.12) 0px 5px 15px;

  :global {
    .ant-card-head {
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      border-bottom: none;

      &-wrapper {
        border-bottom: 1px solid #e8e8e8;
      }

      &-title {
        @include helvetica-neue;
        font-size: 30px;
        padding-left: 50px;
      }
    }
    .ant-table-tbody > tr:last-child > td {
      border-bottom: none;
    }

    .ant-card-body {
      padding: 0 0 24px;
    }
  }
}