.custom-switcher {
  display: flex;
}

.custom-switch-item {
  flex: 1;
  display: block;
  margin: 0;

  &:first-child {
    .custom-switch-item-label {
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }

  &:last-child {
    .custom-switch-item-label {
      border-radius: 0 0.25rem 0.25rem 0;
    }
  }

  .custom-control-input:checked {
    & ~ .custom-switch-item-label {
      background-color: $primary;
      border-color: $primary;
      color: #fff;
    }
  }
}

.custom-switch-item-label {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary;
  border: 1px solid $gray-300;
  background-color: #fff;
  height: calc(2.75rem + 2px);
  padding-left: .75rem;
  padding-right: .75rem;
  cursor: pointer;
}
