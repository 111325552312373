.card {
  z-index: 10;
  display: inline-block !important;
}

.vLine {
  z-index: 0;
  top: -66px;
}

.hLine {
  width: 40px;
  top: 40px;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.categoryType {
  cursor: pointer;
}
