@import '../../../assets/scss/bootstrap/functions';
@import '../../../assets/scss/custom/variables';


.fieldItemCard {
  min-height: 120px;
  margin: 20px 0;
  cursor: grab;

  &.dragging {
    border: 2px dashed $dark;
    background-color: rgba(0, 0, 0, 0.2);
    cursor: grabbing;
  }

  .dropHere {
    text-align: center;
    line-height: 120px;
    width: 100%;
  }
}

