@import '../../assets/scss/bootstrap/functions';
@import '../../assets/scss/custom/variables';

.backgroundContainer {
  z-index: 0;
  height: 80%;
}

.frontContainer {
  z-index: 1;
}

.servicesCard {
	height: 346px;
}

.servicesCardBody {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.description {
	font-weight: 400;
}

@media only screen and (min-width: map-get($grid-breakpoints, "lg")) {
	.servicesCard {
		height: 358px;
	}
}

@media only screen and (min-width: map-get($grid-breakpoints, "xl")) {
	.servicesCard {
		height: 346px;
	}
}

@media only screen and (max-width: map-get($grid-breakpoints, "lg")) {
	.servicesCard {
		height: 400px;
	}

  .servicesCardBody {
		padding-top: 2rem !important;
  }

  .servicesTitle {
  	font-size: 1.2625rem !important;
  }

  .description {
  	font-size: 1.3rem !important;
  	font-weight: 300;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {
	.servicesCard {
		height: 368px;
	}
	.servicesTitle {
		font-size: 1.5rem !important;
	}
}
