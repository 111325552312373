.textEditor {
  width: 100%;
  height: 100%;

  .editorScroll {
    max-height: 650px;
    overflow-y: auto;
  }

}
