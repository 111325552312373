@import '../../assets/scss/bootstrap/functions';
@import '../../assets/scss/custom/variables';

.item {
  min-height: 520px;
  border-radius: 0.5rem;
}

.mmCatContainer {
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 1rem;
}

.mmCatCategory {
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
}

.mmCatSubcategory {
  font-size: 1.5rem;
  font-weight: 500;
  font-style: italic;
}

.subscribe {
  background-color: $white;
  height: 50px;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.controls {
  position: sticky !important;
}

.indicator {
  padding: 1rem;
  cursor: pointer;
  display: block;
}

@media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
  .item {
    background-color: $white;
    box-shadow: $box-shadow;
  }
  .indicator {
    display: inline-block;
    width: 100%;
  }
}

@media only screen and (max-width: map-get($grid-breakpoints, 'sm')) {
  .indicator {
    padding: 0.5rem;
    display: flex;
    align-items: center;

    :global {
      .h4 {
        font-size: 0.8rem;
      }
    }
  }

  .item {
    min-height: 450px;
  }

  .controls {
    position: relative !important;
  }
}
