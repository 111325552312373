@import '../../assets/scss/bootstrap/functions';
@import '../../assets/scss/custom/variables';

@mixin medium-base-styles {
  // Styles from Medium.com
  color: rgba(0, 0, 0, 0.84);
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

$medium-title-font: medium-content-sans-serif-font, 'Lucida Grande',
  'Lucida Sans Unicode', 'Lucida Sans', Geneva, Arial, sans-serif;
$medium-body-font: medium-content-title-font, 'Helvetica Neue', Helvetica,
  'Times New Roman', Times, serif;

:global {
  -webkit-font-smoothing: antialiased;

  .readerPage {
    background: #f8f9fe;
    background: -moz-linear-gradient(
      left,
      #f8f9fe 0%,
      #ffffff 30%,
      #ffffff 100%
    );
    background: -webkit-linear-gradient(
      left,
      #f8f9fe 0%,
      #ffffff 30%,
      #ffffff 100%
    );
    background: -ms-linear-gradient(
      left,
      #f8f9fe 0%,
      #ffffff 30%,
      #ffffff 100%
    );
    background: linear-gradient(
      to right,
      #f8f9fe 0%,
      #ffffff 30%,
      #ffffff 100%
    );
    background: linear-gradient(
      to right,
      #f8f9fe 0%,
      #ffffff 30%,
      #ffffff 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#F8F9FE', endColorstr='#ffffff', GradientType=1 );

    .card-body {
      .card-title {
        @include medium-base-styles;
        font-family: $medium-title-font;
        line-height: 48px;
        font-size: 40px;
      }

      .card-title ~ .h3 {
        @include medium-base-styles;
        font-family: $medium-title-font;
        color: rgba(0, 0, 0, 0.54) !important;
        line-height: 34px;
        font-size: 26px;
        font-weight: 300;

        &.basedOn {
          font-size: 22px;
          line-height: 28px;
        }
      }

      .card-title + .h3 + .h5 {
        @include medium-base-styles;
        font-family: $medium-title-font;
        color: rgba(0, 0, 0, 0.34) !important;
        line-height: 20px;
        font-size: 14px;
        font-weight: 600 !important;
      }

      .card-text {
        p {
          @include medium-base-styles;
          font-family: $medium-body-font;
          line-height: 1.58;
          font-size: 21px;
          letter-spacing: -0.004em;
          -webkit-font-smoothing: antialiased;
          /*
					font-size: 1.1rem;
					font-weight: 400;
					color: #434b5f;
					text-shadow: 0px 0px 1px rgba(0,0,0,0.1);
					*/
        }
      }
    }

    @media only screen and (max-width: map-get($grid-breakpoints, 'lg')) {
      background: #f8f9fe;

      .carousel.slide {
        box-shadow: $box-shadow-lg;
      }
    }
  }

  .business-weekly-card-body.card-body {
    .card-text {
      p {
        font-weight: 400;
        font-size: 1rem;
      }
    }
  }
}
